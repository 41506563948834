#flyoutMenu2 {
  width: 40vw;
  height: 25vh;
  background-color: #FFFFFF;
  border-radius: 20px;
  opacity: 0.85;
  position: fixed;
  top: 15vh;
  left: 70vw;
  transition: transform .5s
              cubic-bezier(0, .52, 0, 1);
  z-index: 1001;
}
  #flyoutMenu2.hide {
  transform: translate3d(0vw, 0, 0);
}
  #flyoutMenu2.show {
  transform: translate3d(10vw, 0, 0);
}
  #flyoutMenu2 h2 a {
  color: #333;
  margin-left: 15px;
  text-decoration: none;
}
  #flyoutMenu2 h2 a:hover {
  text-decoration: underline;
}
