#buttonAndroid {
  top: 69vh;
  position: relative;
  overflow: hidden;
  left: -55vw;
  width: 25vw;
  height: 15vh;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
  z-index: 10;
}

#buttonAndroid:hover {
  cursor: pointer;
  transform: translate3d(3vw, 0, 0);
}

#buttonAndroid:active {

}
