#button {
  top: 5vh;
  position: relative;
  overflow: hidden;
  left: -5vw;
  width: 25vw;
  height: 7vh;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
  z-index: 10;
}

#button:hover {
  cursor: pointer;
  transform: translate3d(3vw, 0, 0);
}

#button:active {

}
