#texta {
  background-color: #d9d9d9;
  width: 100%;
  height: 16vh;
  border-style: hidden;
  resize: none;
  padding-top: 8%;
  text-align: center;
}

#button1 {
  float:left;
  margin-left: 6vw;
}

#button1.visible {
  opacity: 1;
}

#button1.invisible {
  opacity: 0;
}

#button2 {
margin-left: 18vw;
}

#button2.visible {
  opacity: 1;
}

#button2.invisible {
  opacity: 0;
}

#buttonNo {
  width: 6vw;
  height: 5vh;
}

#buttonYes {
  width: 6vw;
  height: 5vh;
}
