#texta {
  background-color: #d9d9d9;
  width: 100%;
  height: 16vh;
  border-style: hidden;
  resize: none;
  padding-top: 10%;
  text-align: center;
}

#button1 {
  float:left;
  margin-left: 6vw;
}

#button1.visible {
  opacity: 1;
}

#button1.invisible {
  opacity: 0;
}

#button2 {
margin-left: 18vw;
}

#button2.visible {
  opacity: 1;
}

#button2.invisible {
  opacity: 0;
}

#titlePopup2 {
  width: 40vw;
  background-color: #009900;
  font-size: 1em;
  color : white;
  padding: 0.3vw;
  padding-left: 1vw;
  margin-bottom: 0.5vh;
  border-radius: 10px 10px 0px 0px;
  z-index: 0;
}

#popup2Table{
  margin-left: 10vw;
  margin-top: 4vh;
  max-width: 100vw;
  width: 20vw;
  border-spacing: 2px;
  border-collapse: separate;
  border-style: hidden;
}

#popup2cell {
  background-color: #f2f2f2;
  width: 20vw;
  height: 3.5vh;
  border-radius: 6px;
  text-align: center;
  font-size: 1em;
	text-decoration:none;
  border: none;
  outline:none;
}

#cellButton {
  background-color:transparent;
	display:inline-block;
	cursor:pointer;
	color:#54534d;
	text-decoration:none;
  border: none;
  outline:none;
}

#cellButtonRed {
  background-color:#ffb3b3;
	display:inline-block;
	cursor:pointer;
	color:#54534d;
	text-decoration:none;
  border: none;
  outline:none;
}

#cellButtonInvisible {
  opacity: 0.01;
  display:inline-block;
  cursor:pointer;
  color:#54534d;
  text-decoration:none;
  border: none;
  outline:none;
}

#button2No {
  width: 6vw;
  height: 5vh;
}

#button2Yes {
  width: 6vw;
  height: 5vh;
}
