#wrapp {
  position: absolute;
  font-family: Candara;
  font-size: 5em;
  font-weight: bold;
  color : white;
  left: 52vw;
  top: 49vh;
  text-shadow: 4px 4px #404040;
  z-index: 1;
}

#other {
  position: absolute;
  font-family: Candara;
  font-size: 5em;
  font-weight: bold;
  color : white;
  text-shadow: 4px 4px #404040;
  z-index: 1;
}

#jelen {
  position: absolute;
  width: 50vw;
  left: 6vw;
  top: 1vh;
}

#countedFaligazda {
  position: absolute;
  font-size: 1.6em;
  width: 30vw;
  left: 1vw;
  top: 6.5vh;
  float: left;
}

#faligazda {
  position: absolute;
  width: 50vw;
  left: 6vw;
  top: 12vh;
}

#countHely {
  position: absolute;
  font-size: 2em;
  width: 30vw;
  left: 4vw;
  top: 17vh;
  float: left;
}

#hely {
  position: absolute;
  width: 50vw;
  left: 10vw;
  top: 24vh;
}

#rend {
  position: absolute;
  width: 50vw;
  left: 3vw;
  top: 34vh;
}
