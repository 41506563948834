#flyoutMenu3 {
  width: 70vw;
  height: 45vh;
  position: absolute;
  top: 42vh;
  left: 55vw;
  transition: transform .5s
              cubic-bezier(0, .52, 0, 1);
  z-index: 1001;
}
  #flyoutMenu3.hide {
  transform: translate3d(0vw, 0, 0);
}
  #flyoutMenu3.show {
  transform: translate3d(23.5vw, 0, 0);
}
  #flyoutMenu3 h2 a {
  color: #333;
  margin-left: 15px;
  text-decoration: none;
}
  #flyoutMenu3 h2 a:hover {
  text-decoration: underline;
}
