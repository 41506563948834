#title {
  background-color: #009900;
  font-size: x-large;
  font-weight: bold;
  color : white;
  padding: 0.3vw;
  padding-left: 1vw;
  margin-bottom: 0.5vh;
  border-radius: 20px 0 0 0;
}

#tableCell {
  width: 8vw;
  height: 4vh;
  margin: 5px;
}

#datumTable {
  height: 16vh;
  margin-left: 1vw;
  border-spacing: 2px;
  border-collapse: separate;
  border-style: hidden;
  overflow-y: auto;
}

#wrap {
  width: 26vw;
  height: 35vh;
  }

#foglal {
  padding-top: 2vh;
  padding-left: 0.5vw;
  font-size: xx-large;
}
