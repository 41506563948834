#flyoutMenuHely {
  width: 80vw;
  height: 60vh;
  background-color: #FFFFFF;
  border-radius: 20px;
  opacity: 0.85;
  position: fixed;
  padding-left: 2vw;
  top: 25vh;
  left: 0;
  transition: transform .5s
              cubic-bezier(0, .52, 0, 1);
  overflow: auto;
  z-index: 1001;
}
  #flyoutMenuHely.hide {
  transform: translate3d(-100vw, 0, 0);
}
  #flyoutMenuHely.show {
  transform: translate3d(-2vw, 0, 0);
  overflow: hidden;
}
  #flyoutMenuHely h2 a {
  color: #333;
  margin-left: 15px;
  text-decoration: none;
}
  #flyoutMenuHely h2 a:hover {
  text-decoration: underline;
}

#titleHely {
  position: absolute;
  width: 80vw;
  background-color: #009900;
  font-size: x-large;
  font-weight: bold;
  color : white;
  padding: 0.3vw;
  padding-left: 1vw;
  margin-bottom: 0.5vh;
  border-radius: 0px 20px 0px 0px;
  z-index: 0;
}

#floatingButton {
  position: relative;
  z-index: 1111;
}

#helyContainer {
  z-index: 0;
}

#buttonBack {
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  left: 69.5vw;
  width: 11vw;
  height: 5vh;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
}

#buttonBack:hover {
  cursor: pointer;
  transform: translate3d(-2vw, 0, 0);
}

#buttonBack:active {

}
