th {
  background-color: #66cc66;
  width: 80px;
  padding: 5px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  text-align: center;
  font-size: 1em;
}


#cellTime {
  background-color: #f2f2f2;
  width: 80px;
  height: 30px;
  border-radius: 6px;
  text-align: center;
  font-size: 0.8em;
}


#cellButton {
  background-color:transparent;
	display:inline-block;
	cursor:pointer;
	color:#54534d;
	text-decoration:none;
  border: none;
  outline:none;
}

#cellButtonRed {
  background-color:#ffb3b3;
	display:inline-block;
	cursor:pointer;
	color:#54534d;
	text-decoration:none;
  border: none;
  outline:none;
}

#cellButtonInvisible {
  opacity: 0.01;
  display:inline-block;
  cursor:pointer;
  color:#54534d;
  text-decoration:none;
  border: none;
  outline:none;
}

#wrapper {
  position: fixed;
  margin-top: 4vh;
  width: 74vw;
  height: 45vh;
  border-radius: 6px;
  overflow-x: auto;

}

#inside {
  width: 1510px;
}

#timeTable{
  margin-left: 20px;
  margin-top: 20px;
  max-width: 100vw;
  border-spacing: 2px;
  border-collapse: separate;
  border-style: hidden;

}

#oneX {
  position: absolute;
  width: 38vw;
  height: 28vh;
  border-style: hidden;
  margin-top: 1vh;
}

#two {
  position: absolute;
  width: 38vw;
  height: 28vh;
  margin-left: 27vw;
  margin-top: 1vh;
}

#three {
  position: absolute;
  width: 38vw;
  height: 28vh;
  margin-left: 55vw;
  margin-top: 1vh;
}


#popup {
  width: 20vw;
  height: 20vh;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  background-color: green;
  opacity: 0.85;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform .5s
              cubic-bezier(0, .52, 0, 1);
  overflow: scroll;
  z-index: 1011;
}

#popup.hide {
  transform: translate3d(0, 100vw, 0);
}

#popup.show {
  transform: translate3d(0, 0vw, 0);
}

#dropdownDiv {
  position: absolute;
  width: 10vw;
  height: 5vh;
  margin-left: 2vw;
}

#falugazdaszDiv {
  position: absolute;
  width: 30vw;
  height: 5vh;
  margin-left: 30vw;
  font-size: xx-large;
}
