.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

#keret {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#popup {
  width: 30vw;
  height: 30vh;
  background-color: #d9d9d9;
  padding: 20px;
  opacity: 0.85;
  overflow: hidden;
  border-radius: 10px;
  top: 20%;
  left: 50%;
  margin-left: -15vw;
  transition: transform .5s
              cubic-bezier(.8, .52, 0, 1);
  z-index: 1011;
}

#popup.hide {
  transform: translate3d(0, 80vw, 0);
}

#popup.show {
  transform: translate3d(0, 50vw, 0);
}

#popup2 {
  width: 40vw;
  height: 60vh;
  background-color: #d9d9d9;
  opacity: 0.85;
  overflow: hidden;
  border-radius: 10px;
  top: 20%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  transition: transform .5s
              cubic-bezier(.8, .52, 0, 1);
  z-index: 1010;
}

#popup2.hide {
  transform: translate3d(0, 80vw, 0);
}

#popup2.show {
  transform: translate3d(0, 50vw, 0);
}
